<template>
	<div>
		<datatable :fillable="false" :data="admins" :columns="columns" />
	</div>
</template>
<script>
	export default {
		props: {
			staff: {
				type: Object,
				default: null
			}
		},
		data() {
			return {
				columns: [{
					name: 'name',
					th: 'Admin',
					render: admin => `
						<div>${admin.name} ${admin.last_name}</div>
						<div class="text-xs text-gray-600">${admin.roles?.map(role => role.name).join(', ')}</div>
					`
				}, {
					name: 'status',
					th: 'Status',
					render: admin => {
						switch (admin ?.pivot ?.status) {
							case 'attended':
								return `
									<div class="text-xs text-green-700 flex items-center">
										<ion-icon name="checkmark-outline" class="mr-2"></ion-icon>
										<span style="margin-top: 2px">Attended</span>
									</div>
								`;
							case 'contacted':
								return `
									<div class="text-xs text-blue-700 flex items-center">
										<ion-icon name="checkmark-outline" class="mr-2"></ion-icon>
										<span style="margin-top: 2px">Contacted</span>
									</div>
								`;
							case 'pending':
							default:
								return `
									<div class="text-xs text-orange-700 flex items-center">
										<ion-icon name="remove-circle-outline" class="mr-2"></ion-icon>
										<span style="margin-top: 2px">Pending</span>
									</div>
								`;
						}
					}
				}]
			}
		},
		computed: {
			admins() {
				return this.staff?.admins || []
			}
		}
	}
</script>